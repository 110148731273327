<!--  -->
<template>
  <div class="content">
    <breadcrumb></breadcrumb>
    <!-- 内容 -->
    <div class="me_content">
      <!-- 左边 -->
      <div class="me_content_left">
        <div class="editData">
          <ul>
            <li v-href="item.value" v-for="(item, index) in tabList" :key="index" :class="{ editDataBgc: $route.name === item.value }" @click="$handleRoute(item.value)" >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>

      <!-- 右边 -->
      <div class="me_content_right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabActiveName:"",
      tabs:[
        {name:'个人资料' , value:"meInfoPersonalData"},
        {name:'教师资料' , value:'meInfoEditTeach'},
        {name:'账户安全' , value:'meInfoAccount'},
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {
    tabList(){
      let tabs = [...this.tabs]
      if(this.userType === 2 && this.$packageConfig.version >= '2.0.0'){
        tabs.splice(0,1)
      }else{
        tabs.splice(1,1)
      }
      return tabs;
    },
  },
  methods:{
    routeUpdate(){
      if(this.tabList.map(d=>d.value).indexOf(this.$route.name) === -1){
        this.$handleRoute(this.tabList[0].value , true);
      }
    },
  },
};
</script>
<style  lang='less' scoped>
 li {
    list-style-type: none;
  } 
.me_content {
  display: flex;
  .me_content_left {
	flex: 1;
    width: 20%;
    height: auto;
    background-color: #fff;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    .editData {
      ul {
        .editDataBgc {
          background: #00957e;
          color: #fff;
        }
        li {
          height: 60px;
          line-height: 60px;
          background: #fff;
          border-radius: 4px 4px 0px 0px;
          // border: 1px solid #979797;
          padding-left: 12%;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #191919;
          cursor: pointer;
        }
      }
    }
  }
  .me_content_right {
    width: 80%;
    // height: 200px;
    margin-left: 1.65rem;
  }
}
</style>

